import React from "react";
import { Container, Form } from "react-bootstrap";
import axios from "axios";
import { DEVICES } from "../../../constants/layout";
import styled from "styled-components";
import Button from "../../shared/buttons/Button";
import { useFormik } from "formik";
import { useAuth } from "../../../contexts/AuthContext";
import ListData from "./ListData";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  min-width: 640px;
  border-radius: 12px;
  padding: 14px;
  padding-bottom: 80px;
`;

const ToolBarContainer = styled(Container)`
  background-color: aliceblue;
  margin-bottom: 16px;
`;

const initialValues = {
  paymentId: "",
  confirmPaymentId: "",
  cancelPaymentId: "",
};

const PaymentList = () => {
  const { currentUser } = useAuth();
  const { values, handleChange } = useFormik({ initialValues });

  const handleGetPaymentInfo = async () => {
    if (values.paymentId) {
      const response = await axios.post(
        "http://127.0.0.1:5001/yourphoto-app/us-central1/getPaymentDetails",
        { paymentId: values.paymentId }
      );
      console.log(response);
    } else {
      alert("error");
    }
  };
  const handleConfirmPayment = async () => {
    if (values.confirmPaymentId) {
      const response = await axios.post(
        "http://127.0.0.1:5001/yourphoto-app/us-central1/confirmPayment",
        { paymentId: values.confirmPaymentId }
      );
      console.log(response);
    } else {
      alert("error");
    }
  };
  const handleCancelPayment = async () => {
    if (values.cancelPaymentId) {
      const response = await axios.post(
        "http://127.0.0.1:5001/yourphoto-app/us-central1/cancelPayment",
        { paymentId: values.cancelPaymentId }
      );
      console.log(response);
    } else {
      alert("error");
    }
  };

  const handleInitPayment = async () => {
    try {
      if (!currentUser.uid) return;

      const response = await axios.post(
        "http://127.0.0.1:5001/yourphoto-app/us-central1/initPayment",
        { amount: 10, userId: currentUser.uid }
      );

      console.log("Response from function:", response.data);

      if (!response.data.paymentId) throw new Error("error processing payment");

      window.open(
        `https://servicestest.ameriabank.am/VPOS/Payments/Pay?id=${response.data.paymentId}&lang=am`
        //   "_blank"
      );
    } catch (error) {
      console.error("Error calling function:", error);
    }
  };

  return (
    <Paper>
      <ToolBarContainer>
        <Button
          onClick={handleInitPayment}
          className="my-4"
          variant="secondary"
        >
          Test Pay
        </Button>
        <Form.Group className="mb-3" controlId="formGridComment">
          <Form.Control
            name="paymentId"
            placeholder="Enter payment ID"
            value={values.paymentId}
            onChange={handleChange}
          />
          <Button onClick={handleGetPaymentInfo} className="my-2">
            Get Payment info
          </Button>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGridComment">
          <Form.Control
            name="confirmPaymentId"
            placeholder="Enter payment ID to confirm"
            value={values.confirmPaymentId}
            onChange={handleChange}
          />
          <Button onClick={handleConfirmPayment} className="my-2">
            Confirm Payment
          </Button>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGridComment">
          <Form.Control
            name="cancelPaymentId"
            placeholder="Enter payment ID to cancel"
            value={values.cancelPaymentId}
            onChange={handleChange}
          />
          <Button
            variant="warning"
            onClick={handleCancelPayment}
            className="my-2"
          >
            Cancel Payment
          </Button>
        </Form.Group>
      </ToolBarContainer>
      <Container>
        <ListData />
      </Container>
    </Paper>
  );
};

export default PaymentList;
