import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { Span } from "../../shared/styledElements/Span";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../contexts/AuthContext";
import styled from "styled-components";
import { DEVICES } from "../../../constants/layout";
import { db } from "../../../firebase";

const Paper = styled.div`
  @media ${DEVICES.tablet} {
    width: 100%;
    padding: 2px;
  }
  min-width: 640px;
  border-radius: 12px;
  padding: 14px;
  padding-bottom: 80px;
`;

const DataContainer = styled.div`
  @media ${DEVICES.tablet} {
  }
`;

const OrderItem = styled.div`
  background-color: "white";
  border-radius: 12px;
  box-shadow: 0px 1px 8px 1px gray;
  padding: 14px;
  margin-bottom: 20px;
`;

const rowTitles = ["ID", "Phone", "Address", "Date", "Info", "Price", "Action"];

const ListData = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const history = useHistory();
  const [orderForDelete, setOrderForDelete] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await db.collection("payments").get();

        if (res.empty) {
          // No documents found
          setPayments([]);
          setLoading(false);
          return;
        }

        const ordersFromDB = res.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id,
        }));

        setPayments(ordersFromDB);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    getData();
  }, [refetch]);

  return (
    <DataContainer>
      {!!payments.length && (
        <Row>
          {rowTitles.map((title) => (
            <Col key={title}>
              <strong>{title}</strong>
              &nbsp;
            </Col>
          ))}
        </Row>
      )}
      {!!payments.length &&
        payments.map((order, i) => {
          const {
            key,
            user_name,
            user_lastname,
            order_info,
            order_price,
            user_uid,
            user_phone,
            user_address,
          } = order;
          return (
            <OrderItem key={i}>
              <Row>
                <Col>
                  <Span
                    clickable
                    onClick={() => {
                      if (user_uid) {
                        history.push(`/dashboard/user/${user_uid}`);
                      }
                    }}
                  >
                    <strong>{key || "-"}</strong>
                  </Span>
                </Col>
                <Col>{user_phone || "-"}</Col>
                <Col>{user_address || "-"}</Col>
                <Col>
                  <Span>
                    {typeof order.order_date === "string" || !order.order_date
                      ? order.order_date || "-"
                      : order.order_date?.toDate()?.toLocaleDateString()}
                  </Span>
                </Col>
                <Col>{order_info || "-"}</Col>
                <Col>{order_price || "-"}</Col>
                <Col>
                  <DropdownButton
                    variant="light"
                    as={ButtonGroup}
                    align="start"
                    title=""
                    id="dropdown-menu-align-responsive-1"
                  >
                    <Dropdown.Item>Edit</Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setOrderForDelete({
                          event: "delete",
                          id: key,
                          title: `${user_name} ${user_lastname || ""}`,
                        })
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            </OrderItem>
          );
        })}
    </DataContainer>
  );
};

export default ListData;
