import React from "react";
import Order from "./components/Order/Order";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Cart from "./components/Cart/Cart";
import Navbar from "./components/Navbar/Navbar";
import Checkout from "./components/Cart/Checkout";
import SignIn from "./components/Authentication/SignIn";
import SignUp from "./components/Authentication/SignUp";
import { AuthProvider } from "./contexts/AuthContext";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import PrivateRoute from "./components/Authentication/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Products from "./components/Products/Products";
import { MainWrapper } from "./components/shared/styledElements/MainWrapper.jsx";
import HomePage from "./components/Home/Home";
import Account from "./components/Account/Account";
import UnderConstruction from "./components/UnderConstructionPage/UnderConstruction";
import Dashboard from "./components/Dashboard/Dashboard";
import Photography from "./components/Photography/Photography";
import Payments from "./components/Dashboard/Payments/Payments.jsx";

const App = () => {
  const isUnderConstrucion = false;

  if (isUnderConstrucion) {
    return <UnderConstruction />;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
      />
      <Router>
        <AuthProvider>
          <Navbar />
          <MainWrapper>
            <Switch>
              <PrivateRoute path="/profile/:profileRoute" component={Account} />
              <PrivateRoute path="/profile" exact component={Account} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/dashboard/payments" component={Payments} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/order/:id" component={Order} />
              <Route path="/order" exact component={Order} />
              <Route path="/cart/checkout" component={Checkout} />
              <Route path="/cart" exact component={Cart} />
              <Route path="/prints" exact component={Products} />
              <Route path="/photography" exact component={Photography} />
              <Route path="/" exact component={HomePage} />
              <Redirect to="/" />
            </Switch>
          </MainWrapper>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
